<template>
    <div>

        <el-main style=" padding-top: 5px;padding-bottom: initial  ">
            <el-form label-width="80px">
                <el-row>
                    <el-col :lg="24">
                        <div :class='[this.$store.getters.getRunTimeTest ? "warning" : "tip"]'>
                            <el-row>
                                <el-col :lg="24" style="line-height: 27px;">
                                    <strong style=" font-size: 16px; ">税率设置</strong>
                                </el-col>
                            </el-row>
                        </div>
                    </el-col>
                    <el-col :lg="24">
                        <el-row>
                            <el-col :lg="9">
                                <el-button style=" margin-top: 8px;margin-bottom: 8px; " type="primary" size="mini"
                                           @click="addEvent">添加税率设置
                                </el-button>
                                <el-button style=" margin-top: 8px; " id="createBill" type="primary" round size="mini"
                                           @click="getList">搜索查询
                                </el-button>
                            </el-col>
                        </el-row>
                    </el-col>

                </el-row>
                <el-row>
                    <el-col :lg="24">
                        <vxe-grid ref="xGrid" v-bind="gridOptions" @page-change="handlePageChange">
                            <template #operate="{row}">
                                <template v-if="$refs.xGrid.isActiveByRow(row)">
                                    <vxe-button icon="el-icon-document-checked" status="primary" title="保存" circle
                                                @click="addRoUpdateEditEvent(row)"></vxe-button>
                                </template>
                                <template v-else>
                                    <vxe-button icon="el-icon-edit-outline" title="编辑" circle
                                                @click="editRowEvent(row)"></vxe-button>
                                </template>
                                <vxe-button icon="el-icon-delete" title="删除" circle
                                            @click="removeRowEvent(row)"></vxe-button>
                            </template>
                        </vxe-grid>
                    </el-col>
                </el-row>
            </el-form>
        </el-main>

        <el-dialog width="420px" :title="isAdd ?  '新增税率设置' : '编辑税率设置'" :visible.sync="showEdit"
                   :close-on-click-modal="false">
            <el-form ref="formData" :rules="formDataRules" :model="formData"
                     label-width="100px">
                <el-row>
                    <el-col :md="24">
                        <el-form-item label="供应商:">
                            <el-select ref="supplier" v-model="formData.supplier" @change="handleSupplierChange"
                                       style="width: 100%"
                                       @keyup.native.enter="$_focusNext('remark_warehouse_bill')"
                                       default-first-option remote
                                       :remote-method="$_searchAllCompany" clearable filterable
                                       size="mini"
                                       placeholder="可搜索查询">
                                <el-option
                                        v-for="item in $store.getters.getAllCompanyList"
                                        :key="item.company_uuid"
                                        :label="item.company_name"
                                        :value="item">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="">
                        <vxe-button size="mini" @click="changeTaxName('(板)')" content="(板)" status="primary" style=" vertical-align: text-top; "></vxe-button>
                        <vxe-button size="mini" @click="changeTaxName('(方)')" content="(方)" status="primary" style=" vertical-align: text-top; "></vxe-button>
                        <vxe-button size="mini" @click="changeTaxName('(足方)')" content="(足方)" status="primary" style=" vertical-align: text-top; "></vxe-button>
                        </el-form-item>
                    </el-col>
                    <el-col :lg="24">
                        <el-form-item label="税率名字" prop="name">
                            <el-input v-model="formData.name" size="mini" style="width: 250px"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :lg="24">
                        <el-form-item label="税率" prop="rate">
                            <el-input type="number" size="mini" style="width: 250px" :step="0.01" v-model="formData.rate">
                                <template slot="append">%</template>
                            </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="showEdit = false">取 消</el-button>
                <el-button type="primary" @click="addRoUpdateEditEvent">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
    import {filterRender} from "@/utils/gird-filter";


    export default {

        data() {
            return {
                showEdit: false,
                isAdd: false,
                uuid: '',
                gridOptions: {
                    importConfig: {
                        remote: true,
                        importMethod: this.importMethod,
                        modes: ['insert'],
                        type: ['xlsx'],
                        types: ['xlsx']
                    },
                    exportConfig: {type: ['xlsx'], types: ['xlsx']},
                    border: true,
                    resizable: true,
                    keepSource: true,
                    showOverflow: true,
                    height: 650,
                    loading: false,
                    pagerConfig: {
                        total: 0,
                        currentPage: 1,
                        pageSize: 20,
                        pageSizes: [10, 20, 50, 100, 200, 500]
                    },
                    editConfig: {
                        // 设置触发编辑为手动模式
                        trigger: 'manual',
                        // 设置为整行编辑模式
                        mode: 'row',
                        // 显示修改状态和新增状态
                        showStatus: true,
                        // 自定义可编辑列头的图标
                        icon: 'fa fa-file-text-o'
                    },
                    columns: [
                        //,visible:false
                        {
                            field: 'id', width: 100, title: '编号', slots: {
                                header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/taxRate/searchHeard')
                            }
                        },
                        {
                            field: 'supplier', width: 280, title: '供应商', slots: {
                                header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/taxRate/searchHeard')
                            }
                        },
                        {
                            field: 'name', width: 280, title: '税率名称', slots: {
                                header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/taxRate/searchHeard')
                            }
                        },
                        {
                            field: 'rate', width: 280, title: '税率',formatter: ({cellValue}) => {
                                return cellValue + '%'
                            } , slots: {
                                header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/taxRate/searchHeard')
                            }
                        },
                        {field: 'operate', title: '操作', width: 120, slots: {default: 'operate'}},
                    ],
                    data: [],
                },
                dialogFormVisible: false,
                tablePage: {
                    total: 0,
                    currentPage: 1,
                    pageSize: 500,
                },
                filterData: {},
                sortData: {},
                formData: {
                    id: '',
                    name: '',
                    rate: '',
                    supplier:'',
                    company_uuid:'',
                },
                formDataRules: {
                    name: [
                        {required: true, message: '请输入税率名字', trigger: 'blur'},
                    ],
                    rate: [
                        {required: true, message: '请输入税率', trigger: 'blur'},
                    ],
                },
                activeName: 'first',
            }
        },
        methods: {
            changeTaxName(value){
                this.formData.name = value;
            },
            editRowEvent(row) {
                this.showEdit = true;
                this.isAdd = false;
                this.$axios({
                    method: 'post',
                    url: '/admin/taxRate/editVue',
                    params: {
                        id: row.id
                    }
                }).then((response) => {         //这里使用了ES6的语法
                    //console.log(response)       //请求成功返回的数据
                    if (response.status === 200) {
                        this.formData.id = response.data.id;
                        this.formData.name = response.data.name;
                        this.formData.rate = response.data.rate;
                        this.formData.supplier = response.data.supplier;
                        this.formData.company_uuid = response.data.company_uuid;
                    }

                }).catch((error) => {
                    console.log(error)
                });
            },
            addRoUpdateEditEvent() {
                let url = '';
                if (this.isAdd) {
                    url = '/admin/taxRate/saveVue';
                } else {
                    url = '/admin/taxRate/updateVue';
                }
                this.$refs.formData.validate((valid) => {
                    if (valid) {
                        this.$axios({
                            method: 'post',
                            url: url,
                            data: {
                                formData : this.formData
                            },
                        }).then((response) => {         //这里使用了ES6的语法
                            //console.log(response)       //请求成功返回的数据
                            if (response.status === 200 && response.data.state == 'ok') {
                                this.$message({
                                    showClose: true,
                                    message: '保存成功',
                                    type: 'success'
                                });
                                this.showEdit = false;
                                this.getList();
                            } else {
                                this.$message({
                                    showClose: true,
                                    message: response.data.msg,
                                    type: 'error'
                                });
                                return
                            }
                        }).catch((error) => {
                            console.log(error)
                        });
                    } else {
                        //console.log('error submit!!');
                        return false;
                    }
                });
            },
            addEvent() {
                this.showEdit = true
                this.isAdd = true
                this.formData.id = '';
                this.formData.name = '';
                this.formData.rate = '';
                this.formData.supplier = '';
                this.formData.company_uuid = '';
            },
            removeRowEvent(row) {
                this.$XModal.confirm('您确定要删除该数据?').then(type => {
                    //const $table = this.$refs.xTable
                    if (type === 'confirm') {
                        //传送删除动作
                        this.$axios({
                            method: 'post',
                            url: '/admin/taxRate/deleteVue',
                            params: {
                                id: row.id
                            }
                        }).then((response) => {         //这里使用了ES6的语法
                            //(response)       //请求成功返回的数据
                            if (response.status === 200 && response.data.state == 'ok') {
                                this.$message({
                                    showClose: true,
                                    message: '删除成功',
                                    type: 'success'
                                });
                                this.getList();
                            } else {
                                this.$message({
                                    showClose: true,
                                    message: response.data.msg,
                                    type: 'error'
                                });
                                return
                            }
                        }).catch((error) => {
                            console.log(error)
                        });

                    }
                })
            },
            getList() {
                this.$axios({
                    method: 'POST',
                    url: '/admin/taxRate',
                    data: {
                        currentPage: this.tablePage.currentPage,
                        pageSize: this.tablePage.pageSize,
                        sortData: this.sortData,
                        filterData: this.filterData,
                    }
                }).then((response) => {         //这里使用了ES6的语法
                    //console.log(response)       //请求成功返回的数据
                    if (response.status === 200) {
                        this.gridOptions.data = response.data.page.list
                        this.tablePage.pageSize = response.data.page.pageSize
                        this.tablePage.total = response.data.page.totalRow
                        this.tablePage.currentPage = response.data.page.pageNumber
                    }
                }).catch((error) => {
                    console.log(error)
                });
            },
            handlePageChange({currentPage, pageSize}) {
                this.tablePage.currentPage = currentPage
                this.tablePage.pageSize = pageSize
                this.getList()
            },
            handleSupplierChange(params){
                this.formData.supplier = params.company_name;
                this.formData.company_uuid = params.company_uuid;
            }
        },
        created() {
            this.getList()
        }
    };


</script>

<style scoped>
    .header-wrapepr {
        display: flex;
        flex-direction: row;
        justcontent: space-between
    }

    .el-date-editor.el-input, .el-date-editor.el-input__inner {
        width: 130px;
    }

    /deep/ .el-form-item__error {
        position: initial !important;
    }

    .el-form-item__content .el-input {
        width: 130px;
    }

    /deep/ .vxe-input--panel {
        position: fixed !important;
        left: auto;
    }

    /deep/ .el-input-number .el-input__inner {
        text-align: initial !important;
    }

    .el-form-item__content .el-input-group {
        vertical-align: initial;
    }
</style>
